import React, { FC, useEffect, useState } from 'react'
import styles from './Home.module.scss'
import { GridItem } from 'modules/Layout/components/GridItem'
import { FlexColumn } from 'components/FlexColumn'
import { Orders } from './Orders'
import { Insights } from './Insights'
import { Devices } from './Devices'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { Feedback } from './Feedback'
import { HarmReductionPager } from './HarmReductionPager'
import { getIsFeedbackEnabled } from 'utils/app'

export const Home: FC = () => {
  const { region, sdkContext } = useConxSdk()
  const [showHarmReductionClaims, setShowHarmReductionClaims] = useState<boolean>()

  useEffect(() => {
    if (!sdkContext) return
    sdkContext.remoteConfig.showHarmReductionClaims.addListener(setShowHarmReductionClaims)
    return () => {
      sdkContext.remoteConfig.showHarmReductionClaims.removeListener(setShowHarmReductionClaims)
    }
  }, [sdkContext])

  const isOrdersEnabled = process.env.NEXT_PUBLIC_ECOMM_ENABLED_REGIONS?.includes(region)
  const isFeedbackEnabled = getIsFeedbackEnabled()

  return (
    <GridItem verticalAlign='top'>
      <div className={styles.Home}>
        <FlexColumn spacing={{ default: 'extraExtraLoose' }}>
          <Devices />
          {showHarmReductionClaims && <HarmReductionPager />}
          <Insights />
          {isOrdersEnabled && <Orders />}
          {isFeedbackEnabled && <Feedback />}
        </FlexColumn>
      </div>
    </GridItem>
  )
}
