import React, { FC, useEffect, useRef, useState } from 'react'
import { useConxSdk } from 'modules/ConxSdkProvider'

export const HarmReductionPager: FC = () => {
  const { composables, userAgent } = useConxSdk()

  // Height is mutable because react owns the layout, but compose is the one that actually
  // measures desired size. The initial value should be close to the real value to minimize
  // jumpiness, but don't worry about being pixel-perfect.
  const [height, setHeight] = useState(482)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentContainer = containerRef.current

    if (currentContainer != null) {
      composables.attachHarmReductionPager(currentContainer, userAgent.isDesktop, setHeight)
    }
  }, [composables])

  // The layout containing this element sizes it based on the card size, but we need to expand past
  // that for paging UI. On Desktop this is a full icon button, and on mobile this is just a peek
  // of the next card.
  const oversize = userAgent.isDesktop ? 48 : 16
  return (
    <div
      ref={containerRef}
      style={{
        display: 'block',
        height: `${height}px`,
        marginLeft: `-${oversize}px`,
        marginRight: `-${oversize}px`,
        width: `calc(100% + ${oversize * 2}px)`,
      }}
    />
  )
}
